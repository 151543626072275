import moment from 'moment'
import "magnific-popup"

import default_song_list from './tracklist.json'

// console.log(default_song_list)
console.log(spotifyApi)

let _ae_member_id = null
let _ae_member_email = null
let _ae_member_first_name = null
let _ae_member_spotify_user = null



jQuery(document).ready(function ($) {

  const alphaSortedSongList = default_song_list.sort((a, b) => a.track_title.localeCompare(b.track_title));

  var list = $('#search').find('ul');
  $.each(alphaSortedSongList, function (index, song) {
    var html = '<li data-track-id="' + song.spotify_uri + '">';
    html += '<span class="track-title">' + song.track_title + '</span>';
    html += '<div class="actions"><a href="#" class="add"><i class="fas fa-plus" aria-hidden="true"></i><span class="sr-only">Add</span></div>';
    html += '</li>';
    list.append(html);
  });

  $('#search ul').on('click', 'a.add', function (e) {
    e.preventDefault();
    var uri = $(this).parents('li').attr('data-track-id');
    var text = $(this).parents('li').find('span.track-title').text();
    var html = '<li data-track-id="' + uri + '"> <span>' + text + '</span><div class="actions"><a href="#" class="remove"><i class="fas fa-minus" aria-hidden="true"></i><span class="sr-only">Remove</span></a></div></li> ';
    $('#playlist ul').append(html).sortable();
  });

  $('#playlist ul').on('click', 'a.remove', function (e) {
    $(this).parents('li').remove();
    e.preventDefault();
  });

  $('.save-btn').on('click', function (e) {
    e.preventDefault();
    spotifyApi.createPlaylist(_ae_member_spotify_user, { name: `Gigi D’Alesso Live – La scaletta di ${_ae_member_first_name}` }, function (err, playlist) {
      console.log(_ae_member_spotify_user)
      if (err) {
        console.error(err);
        alert('An error has occurred while connecting to spotify! Errno: 101');
      } else {
        var tracks = [];

        $('#playlist ul li').each(function () {
          var uri = $(this).attr('data-track-id');
          tracks.push(uri);
        });

        spotifyApi.addTracksToPlaylist(playlist.id, tracks, function (err) {

          console.log('playlist', playlist)

          if (err) {
            console.error(err);
            alert('An error has occurred while connecting to spotify! Errno: 102');
          } else {

            function fileToBase64(filePath, callback) {
              var xhr = new XMLHttpRequest();
              xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                  callback(reader.result.split(',')[1]);
                };
                reader.readAsDataURL(xhr.response);
              };
              xhr.open('GET', filePath);
              xhr.responseType = 'blob';
              xhr.send();
            }

            var playlistUrl = 'https://open.spotify.com/user/' + _ae_member_spotify_user + '/playlist/' + playlist.id;
            var customImageUrl = '';

            fileToBase64(customCoverPath, function (base64String) {
              console.log('base64string', base64String);

              spotifyApi.uploadCustomPlaylistCoverImage(playlist.id, base64String, function () {
                customImageUrl = base64String;

                $('#step-3-confirmation').html(`GIGI D’ALESSIO LIVE - LA SCALETTA DI ${_ae_member_first_name}`);
                $('#step-3-content').html(`<a href="${playlistUrl}" target="_blank"><img src="${customCoverPath}" alt="Custom Playlist Cover Image"></a>`);
                $('#playlist-link').attr('href', playlistUrl);

                setStage(3);
              })
            });
          }
        });
      }
    });
  });


  //Spotify
  let SMEPreSave = {
    state: null,
    spotify_user: null,
    url_vars: [],
    getURLVars: function (callback, form_id) {
      let hash;
      let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
      for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        this.url_vars.push(hash[0]);
        this.url_vars[hash[0]] = hash[1];
      }
      this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
      this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
      this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
      this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
      this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
      if (typeof callback === 'function') {
        callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
      }
    }
  };

  /* String state
  *  The status of the pre-save.
  *  Int ae_member_id 
  *  The Appreciation Engine member ID. This can be passed to the forms processor.
  *  String spotify_user
  *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
  *  String deezery_user
  *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
  */

  SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
    // Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
    if (state === 'success' || state === 'thank-you') {
      $.ajax({
        url: wp_ajax_url,
        method: 'POST',
        data: ({ action: 'get_ae_spotify_token', 'security': wp_nonce, 'ae_member_id': ae_member_id }),
        dataType: 'json',
        success: function (resp) {
          console.log('resp', resp)
          console.log(resp.data.spotify_token);


          _ae_member_email = resp.data.email
          _ae_member_id = ae_member_id
          _ae_member_first_name = resp.data.first_name
          _ae_member_spotify_user = resp.data.spotify_user


          spotifyApi.setAccessToken(resp.data.spotify_token);

          jQuery.ajax({
            url: 'https://subs.sonymusicfans.com/submit',
            method: 'POST',
            xhrFields: { withCredentials: false },
            crossDomain: true,
            method: 'POST',
            data: {
              field_email_address: resp.data.email,
              form: '430745',
              default_mailing_list: 'a0S1p00000TLDMnEAP', //only include if opt-ing in to mailing list
              ae_member_id: ae_member_id,
              ae_segment_id: '2136952',
              ae_brand_id: '4230309',
              source_channel: 'Spotify',
              ae_activities: '{"actions":{"presave":139783},"mailing_list_optins":{"a0S1p00000TLDMnEAP":139855}}'
            },
            error: function () {
              alert('An error has occured! Error: 104');
            }
          });

          setStage(2)

          $('#step-1-btns').html(`<p>Thank you!</p>`)
        },
        error: function () {
          console.log('Error!');
        }
      });
    }

    //  DISABLE BUTTON
    $('input[type="checkbox"]').on('change', function () {
      // Check if the checkbox is checked
      if (this.checked) {
        $('#presave-1').attr('href', 'https://presaves.sonymusicfans.com/?campaign_id=430745&mailing_list_ids=a0S1p00000TLDMnEAP&triggered_sends=&retargeting_consent=0&source_channel=Spotify');
      } else {
        $('#presave-1').attr('href', 'https://presaves.sonymusicfans.com/?campaign_id=430745&mailing_list_ids=&triggered_sends=&retargeting_consent=0&source_channel=Spotify');
      }
    });
  });


});